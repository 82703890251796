import { render, staticRenderFns } from "./MgrAnalysis.vue?vue&type=template&id=96563384&scoped=true"
import script from "./MgrAnalysis.vue?vue&type=script&lang=js"
export * from "./MgrAnalysis.vue?vue&type=script&lang=js"
import style0 from "../../public/css/search.less?vue&type=style&index=0&id=96563384&prod&lang=less&scoped=true&external"
import style1 from "./MgrAnalysis.vue?vue&type=style&index=1&id=96563384&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96563384",
  null
  
)

export default component.exports