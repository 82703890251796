<template>
  <div class="about">
    <div class="titles azjgs">
      <input-list @setData="getData" iptType="1"></input-list>
      <div>
        设备名称：
        <el-select v-model="csbmc" placeholder="全部" @change="sbmcChg" clearable>
          <el-option :label="item.name" v-for="(item, index) in csbmcArr" :key="index" :value="item.id"></el-option>
        </el-select>
      </div>
      <div v-if="$has('viewAllParam')">
        设备类型：
        <el-select v-model="csblx" @change="cxcslx(csblx)" placeholder="全部" clearable>
          <el-option :label="item.codeDesc" v-for="(item, index) in csblxArr" :key="index" :value="item.code"></el-option>
        </el-select>
      </div>
      <div>
        参数类型：
        <el-select v-model="ccslx" placeholder="全部" clearable>
          <el-option :label="item.codeDesc" v-for="(item, index) in ccslxArr" :key="index" :value="item.code"></el-option>
        </el-select>
      </div>
      <span>
        <el-button v-if="$has('viewMgrAnalysis')" type="success" size="mini" @click="searchs" icon="el-icon-search">查 询</el-button>
        <el-button type="info" size="mini" @click="clears" icon="el-icon-delete">重 置</el-button>
      </span>
      <div>
        日期：
        <el-date-picker v-model="dates" clearable :editable="false" type="daterange" size="small" start-placeholder="开始日期" range-separator="至" end-placeholder="结束日期" style="display: inline-flex; width: 210px" value-format="yyyy-MM-dd"> </el-date-picker>
      </div>
    </div>
    <div class="cont">
      <div class="cont-echarts">
        <div id="emain"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { radAnalysisGet, deviceQueryWithPage } from '@/util/http'
import inputList from '@/components/inputList/inputList.vue'
export default {
  components: { inputList },
  name: 'about',
  data() {
    return {
      dates: '', //时间
      csblx: 1, //设备类型
      csblxArr: JSON.parse(sessionStorage.getItem('deviceType')), //设备类型数组
      cgxqy: '', // 查询区域名称
      cjgmc: '', // 查询机构名称
      csbmc: '', // 查询设备名称
      cSuperiorArr: '', // 查询区域名称数组
      cjgmcArr: '', // 查询机构名称数组
      csbmcArr: '', // 查询设备名称数组
      ccslx: '', //设备类型下一级
      ccslxArrs: [
        { codeDesc: '实时辐射剂量', code: 0 },
        { codeDesc: '温度', code: 1 },
        { codeDesc: 'X射线剂量率', code: 2 },
        { codeDesc: 'α剂量率', code: 3 },
        { codeDesc: 'β剂量率', code: 4 },
        { codeDesc: 'γ剂量率', code: 5 },
        { codeDesc: '中子剂量率', code: 6 },
        { codeDesc: '左手手心γ剂量率', code: 7 },
        { codeDesc: '左手手心β活度', code: 8 },
        { codeDesc: '左手手背γ剂量率', code: 9 },
        { codeDesc: '左手手背β活度', code: 10 },
        { codeDesc: '右手手心γ剂量率', code: 11 },
        { codeDesc: '右手手心β活度', code: 12 },
        { codeDesc: '右手手背γ剂量率', code: 13 },
        { codeDesc: '右手手背β活度', code: 14 },
        { codeDesc: '左脚γ剂量率', code: 15 },
        { codeDesc: '左脚β活度', code: 16 },
        { codeDesc: '右脚γ剂量率', code: 17 },
        { codeDesc: '右脚β活度', code: 18 },
        { codeDesc: '探测器1计数率', code: 19 },
        { codeDesc: '探测器2计数率', code: 20 },
      ], //设备类型下一级
      ccslxArr: [],
      myChart: null, //图表
      option: {
        title: {
          text: '',
          left: 'center',
          top: '3%',
          textStyle: { fontSize: 20, color: '#fff' },
          show: true,
        },
        legend: {
          top: 52,
          left: 'center',
          textStyle: { color: '#fff' },
          data: ['最大值', '最小值', '平均值'],
        },
        xAxis: {
          type: 'category',
          splitArea: {
            color: '#f00',
            lineStyle: { color: '#f00' },
          },
          axisLabel: { color: '#fff' },
          splitLine: { show: false },
          boundaryGap: false,
          data: [],
          nameTextStyle: { color: '#fff' },
          name: '日期',
        },
        grid: {
          top: '15%',
          left: '5%',
          right: '5%',
          bottom: '5%',
        },
        yAxis: {
          type: 'value',
          nameTextStyle: { color: '#fff' },
          name: '总值',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#315070'],
              width: 1,
              type: 'solid',
            },
          },
          axisLine: { show: true },
          axisLabel: {
            show: true,
            color: '#ffffff',
            formatter: (e) => {
              return e.toFixed(2)
            },
          },
          axisTick: { show: true },
        },
        tooltip: {
          trigger: 'axis', //触发类型
          backgroundColor: 'rgba(255,255,255,1)', //背景
          textStyle: { color: 'black' },
          axisPointer: {
            //鼠标更随
            type: 'cross',
            label: {
              backgroundColor: '#04349a',
              color: '#fff',
              borderColor: '#aaa',
              borderWidth: '1',
            },
          },
        },
        series: [
          { name: '最大值', data: [], type: 'line', symbolSize: 10 },
          { name: '平均值', data: [], type: 'line', symbolSize: 10 },
          { name: '最小值', data: [], type: 'line', symbolSize: 10 },
        ],
      },
    }
  },
  methods: {
    // 拿值区域和机构
    getData(msg) {
      this.cgxqy = msg[0]
      this.cjgmc = msg[1]
      this.csbmc = ''
      this.csbmcArr = ''
      if (msg[1] != undefined) {
        this.cxsbmc()
      }
    },
    // 查询
    searchs() {
      let params = {
        areaId: this.cgxqy,
        begin: this.dates[0],
        deviceId: this.csbmc,
        deviceType: this.csblx,
        end: this.dates[1],
        organizationId: this.cjgmc,
        index: this.ccslx,
      }
      this.myChart.showLoading({ text: '加载中', color: '#fff', maskColor: 'rgba(0 ,0 ,0 ,0.2 )', zlevel: 0, textColor: '#fff' })
      radAnalysisGet(params).then((data) => {
        if (data) {
          this.option.title.text = this.csblxArr.find((v) => v.code == this.csblx).codeDesc + '  ' + this.ccslxArrs[this.ccslx].codeDesc + '的一周数据统计'
          // this.option.title.text = this.csblxArr[this.csblx - 1].codeDesc + '  ' + this.ccslxArrs[this.ccslx].codeDesc + '的一周数据统计'
          this.option.xAxis.data = []
          this.option.series[0].data = []
          this.option.series[1].data = []
          this.option.series[2].data = []
          var maxData = []
          data.result.map((val) => {
            this.option.xAxis.data.push(val.createDt)
            this.option.series[0].data.push(val.max)
            this.option.series[1].data.push(val.avg)
            this.option.series[2].data.push(val.min)
            maxData.push(val.max)
          })
          // this.option.yAxis.interval = (Math.max.apply(null,maxData)+0.5)/4;
          this.option.yAxis.max = Math.max.apply(null, maxData) + 0.5
        }
        this.renderEcharts()
      })
    },
    // 重置
    clears() {
      this.$bus.$emit('clickResert', 1)
      this.dates = ''
      this.csblx = 1
      this.cgxqy = this.user.areaId
      this.cjgmc = this.user.organizationId
      this.csbmc = ''
      this.cjgmcArr = ''
      this.csbmcArr = ''
    },
    // 设备类型
    sbmcChg() {
      this.csblx = this.csbmcArr.find((v) => v.id == this.csbmc).type
      this.cxcslx(this.csblx)
    },
    // 查询设备名称
    cxsbmc() {
      let params = {
        areaId: this.cgxqy,
        organizationId: this.cjgmc,
        pageSize: 10000,
      }
      deviceQueryWithPage(params).then((data) => {
        // console.log(data);
        if (data) {
          this.csbmcArr = data.result.records
        }
      })
    },
    // 设备类型下一级
    cxcslx(x) {
      this.ccslx = ''
      this.ccslxArr = []
      if (x == 1 || x == 2 || x == 3 || x == 4 || x == 7) {
        this.ccslxArr = this.ccslxArrs.slice(0, 1)
      }
      if (x == 5) {
        this.ccslxArr = this.ccslxArrs.slice(3, 5)
      }
      if (x == 6) {
        this.ccslxArr = this.ccslxArrs.slice(7, 19)
      }
      if (x == 8) {
        this.ccslxArr = this.ccslxArrs.slice(1, 7)
      }
      if (x == 18) {
        this.ccslxArr = this.ccslxArrs.slice(19, 21)
      }
      this.ccslx = this.ccslxArr[0].code
    },
    // 初始化
    renderEcharts() {
      this.myChart.hideLoading()
      this.myChart.clear()
      this.myChart.setOption(this.option)
      window.addEventListener('resize', () => {
        //监听浏览器窗口大小
        this.myChart.resize()
      })
    },
  },
  watch: {
    '$store.state.isCollapse': {
      handler(newVal, oldVal) {
        setTimeout(() => {
          this.myChart.resize()
        }, 500)
      },
    },
    dates(val) {
      if (!val) {
        this.dates = ['', '']
      }
    },
  },
  mounted() {
    this.user = this.$store.state.user
    this.cgxqy = this.user.areaId
    this.cjgmc = this.user.organizationId
    this.$nextTick(() => {
      let titleHeight = document.getElementsByClassName('titles')[0].offsetHeight
      document.getElementsByClassName('cont')[0].style.height = `calc(100% - ${titleHeight}px)`
      // 底部echarts
      this.myChart = this.$echarts.init(document.getElementById('emain'))
      this.cxcslx(this.csblx)
      this.searchs() // 初次调用
    })
  },
}
</script>
<style src="../../public/css/search.less" lang="less" scoped></style>
<style scoped lang="less">
// 主体
.cont {
  background: #04349a;
}
// 底部大的折线图
.cont-echarts {
  width: 100%;
  height: 100%;
}
#emain {
  width: 100%;
  height: 100%;
}
</style>
